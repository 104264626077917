<template>
  <div
      style="display:flex;justify-content: space-between;height: 100%;background: rgb(248, 249, 250);overflow-y:hidden">
    <ContractSideMenu :active-index="'2'">
    </ContractSideMenu>

    <div class="right-panel">
      <MetaRightSideHeader>
        <template #title>{{trashed?"已删除":"文档列表"}}</template>
        <template #middle-right>
          <div class="bg-concord-near-white ml3 bg-search">
            <div data-test-algolia-input-container="" id="algolia-search-box-container" class="relative h-100 bw1"
                 style="width: 253px;">
              <div class="relative concord-mid-gray">
                <input placeholder="请输入关键词" id="searchInput"
                       class="bzbb ember-text-field ember-view bg-white placeholder-concord-mid-gray hover-concord-mid-gray input-reset w-100 h-100 bn pl5 outline-0 fw4 f5 shadow size-medium"
                       aria-label="Search" type="text" @keyup.enter="onSearchInputEnter">
                <div style="transform: translateY(-50%)" class="absolute left-0 flex items-center top-50 h-100 ph2">
                  <i class="material-icons pl2 f3 concord-mid-gray hover-concord-mid-gray ais-search-box--icon pe--none" aria-hidden="true">
                    search
                  </i>
                </div>
              </div>
            </div>
          </div>
        </template>
      </MetaRightSideHeader>

      <div class="contract-list">
        <div class="main-table" style="width: 100%">
          <MetaListTable
              ref="multiple-table"
              :table-data="contractList"
              :table-height="tableHeight"
              @sort-changed="sortChange"
              :show-checkbox="true"
              @cell-click="handleClick"
          >
            <template v-slot:header>
              <div class="action">
                <div class="selector-wrap">
                  <MetaListSelector
                      :list="contractList"
                      :show-operation-action-btn="showDelete||showCancelDelete||showCompletelyDelete||showCancel"
                  >
                    <template #selection>
                      <div style="border-bottom: solid 1px #dbdbdb;margin: 10px 0"></div>
                      <div class="menu-item" data-tag-id="select-contract-draft" @click="checkByStatus(CLMResourceType.CONTRACT,0)" v-show="contractList.find((item)=>item.resourceType===CLMResourceType.CONTRACT&&item.approvalStatus==0&&item.deleteStatus==0)">草稿</div>
                      <div class="menu-item" data-tag-id="select-finalized" @click="checkByStatus(CLMResourceType.CONTRACT,11)" v-show="contractList.find((item)=>item.approvalStatus==11&&item.deleteStatus==0)">定稿</div>
                      <div class="menu-item" data-tag-id="select-wait-to-approve" @click="checkByStatus(CLMResourceType.CONTRACT,10,CLMResourceType.PUBLISHED_TEMPLATE,0)" v-show="contractList.find((item)=>(item.approvalStatus==10 ||((item.resourceType===CLMResourceType.PUBLISHED_TEMPLATE || item.resourceType===CLMResourceType.PRO) && item.approvalStatus==0))&&item.deleteStatus==0)">审批中</div>
                      <div class="menu-item" data-tag-id="select-rejected" @click="checkByStatus(CLMResourceType.CONTRACT,12,CLMResourceType.PUBLISHED_TEMPLATE,2)" v-show="contractList.find((item)=>(item.approvalStatus==12 ||item.approvalStatus==22)&&item.deleteStatus==0)">已驳回</div>
                      <div class="menu-item" data-tag-id="select-signed" @click="checkByStatus(CLMResourceType.CONTRACT,21)" v-show="contractList.find((item)=>item.approvalStatus==21&&item.deleteStatus==0)">已签署</div>
                      <div class="menu-item" data-tag-id="select-canceled" @click="checkByStatus(CLMResourceType.CONTRACT,30)" v-show="contractList.find((item)=>item.approvalStatus==30&&item.deleteStatus==0)">已取消</div>
                      <div class="menu-item" data-tag-id="select-deleted" @click="checkDelete()" v-show="contractList.find((item)=>item.deleteStatus==1)">已删除</div>
                      <div class="menu-item" data-tag-id="select-template" @click="checkTemplate()" v-show="contractList.find((item)=>item.resourceType==CLMResourceType.PUBLISHED_TEMPLATE&&item.deleteStatus==0)">模板</div>
                      <div class="menu-item" data-tag-id="select-template-draft" @click="checkPro()" v-show="contractList.find((item)=>item.resourceType==CLMResourceType.PRO&&item.deleteStatus==0)">模板草稿</div>
                    </template>
                    <template #operation>
                      <div class="menu-item" data-tag-id="delete-clm-resource" @click="deleteResources(false)" v-show="showDelete"><i class="el-icon-delete"></i><span class="ml2">删除</span></div>
                      <div class="menu-item" data-tag-id="cancel-delete-clm-resource" @click="cancelDeleteResources" v-show="showCancelDelete"><i class="el-icon-remove"></i><span class="ml2">取消删除</span></div>
                      <div class="menu-item" data-tag-id="delete-clm-resource-completely" @click="deleteResources(true)" v-show="showCompletelyDelete"><i class="el-icon-delete"></i><span class="ml2">彻底删除</span></div>
                      <div class="menu-item" v-show="false">导出列表</div>
<!--                      <div class="menu-item" @click="downloadFiles"><i class="el-icon-download"></i><span class="ml2">下载</span></div>-->

                      <div class="menu-item" data-tag-id="cancel-contract" v-show="showCancel" @click="cancelContract"><i class="el-icon-refresh" ></i><span class="ml2">取消</span></div>
                    </template>
                  </MetaListSelector>
                </div>
                <div class="action-btn" style="margin-left: 20px" v-show="false">
                  <span>移动</span>
                </div>

                <div class="action-btn" style="margin-left: 20px" v-show="false">
                  <el-tooltip content="下载" placement="bottom">
                    <i class="el-icon-download"></i>
                  </el-tooltip>
                </div>

                <div class="action-btn" style="margin-left: 20px" v-show="false">
                  <span>签署</span>
                </div>

                <div style="display:inline-block;">
                  <el-popover placement="bottom" :width="220" trigger="click">
                    <template #reference>
                      <div class="contract-filter-btn" data-tag-id="filter-clm-resource">
                        <svg style="fill: currentColor;" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                             viewBox="0 0 32 32" class="fill ln-title mr1" v-bind:class="{'concord-blue':filterList.length==0,'silver':filterList.length>0}">
                          <path
                              d="M24 2.667H8a2.67 2.67 0 00-2.667 2.667v5.333c0 .341.131.683.391.943L12 17.887V28a1.335 1.335 0 002.277.942l5.333-5.333c.251-.249.391-.588.391-.943v-4.781l6.276-6.276c.26-.26.391-.6.391-.943V5.333a2.67 2.67 0 00-2.667-2.667z"></path>
                        </svg>

                        <span :style="filterList.length!=0?'color:#999':'color:#5280FF'">筛选</span></div>
                    </template>
                    <div>
                      <div>
                        <div style="padding: 10px">
                          <el-input prefix-icon="el-icon-search" size="small"  placeholder="搜索"
                                    v-model="searchName" @input="addFilter('包含','keywords',searchName,searchName,true,true)"></el-input>
                        </div>
                        <div style="border-bottom: solid 1px #dbdbdb;margin: 0 0 10px 0"></div>
                        <div class="menu-item pd0 menu-filter-item" tabindex="4"  v-for="(filterItem,filterIndex) in filterMenu" :key="filterIndex" v-show="!(filterItem.type=='状态'&&trashed)">
                          <el-popover placement="right"  trigger="hover" >
                            <template #reference>
                              <div class="flex-row" style="width: 100%;height: 40px"><span style="flex: 1">{{filterItem.type}}</span><i
                                  class="el-icon-arrow-right"></i></div>
                            </template>
                            <el-scrollbar :max-height="400">
                              <div class="menu-item menu-filter-item"  v-for="(item,index) in filterItem.items" :key="index" >
                                <el-checkbox  v-model="filterMenu[filterIndex].items[index].checked" @change="addFilter(filterItem.type,filterItem.key,item.name,item.value,true)">
                                  <span :class="item.class"/>{{item.name}}
                                </el-checkbox>
                              </div>
                            </el-scrollbar>


                          </el-popover>
                        </div>

                      </div>
                    </div>
                  </el-popover>
                </div>
                <el-tag closable class="ml2" effect="plain" type="info" style="border-radius: 0;"
                        v-for="(item,index) in filterList" :key="index" @close="clearFilter(index)">
                  <span>{{ typeof(item.names)=='string'?item.names:item.names.join("、") }}</span></el-tag>
              </div>
              <div class="pagination-area">
                <MetaListPagination
                :current-count="currentCount"
                :size="size"
                :current-index="currentIndex"
                :total-count="totalCount"
                @size-change="sizeChange"
                @current-change="currentChange"
                ></MetaListPagination>
              </div>
              <el-popover placement="bottom" :width="220" trigger="click">
                <template #reference>
                  <div class="action-btn">
                    <i class="el-icon-s-operation" style="margin-right: 10px"></i>
                    <i class="el-icon-caret-bottom" style="color: gray"></i>
                  </div>
                </template>
                <div>
                  <div style="padding: 10px">
                    <el-input prefix-icon="el-icon-search" size="small" style="width: 180px" placeholder="搜索"
                              v-model="searchKey"></el-input>
                  </div>
                  <div style="border-bottom: solid 1px #dbdbdb;margin: 0 0 10px 0"></div>
                  <div class="menu-item" tabindex="4" v-for="(item,key) in tableView" :key="key"
                       v-show="item.label.indexOf(searchKey)>=0">
                    <el-checkbox v-model="item.value" :label="item.label"></el-checkbox>
                  </div>

                </div>
              </el-popover>
            </template>

            <template v-slot:default>
              <el-table-column
                  prop="title"
                  label="标题"
                  min-width="300"

                  sortable="custom"
                  sort-by="title"
                  show-overflow-tooltip>
                <template #default="scope">
                  <div style="display: flex;align-items: center;justify-content: flex-start">
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                         style="background-color:#5984C3;margin-right: 10px; flex-shrink:0" fill="white" class="br2" v-if="scope.row.resourceType==CLMResourceType.CONTRACT && scope.row.currentFileType!=FileContentType.PDF" >
                      <path
                          d="M10.059 10.39l1.311-6.42h1.47l-1.966 8.53H9.455L7.832 6.271 6.174 12.5H4.75L2.781 3.969h1.47l1.325 6.41 1.63-6.41h1.241l1.612 6.422z"></path>
                    </svg>
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" style="background-color:white;margin-right: 10px; flex-shrink:0" fill="#E56E2A" class="br2" v-else-if="scope.row.currentFileType==FileContentType.PDF"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm2.017 9.218h-.65v1.295h-1.3v-5.18h1.95a1.95 1.95 0 011.95 1.943c0 1.07-.875 1.942-1.95 1.942zm0-2.59h-.65v1.295h.65a.65.65 0 00.65-.647.65.65 0 00-.65-.648zm2.6-1.295v5.18h1.3c1.434 0 2.6-1.162 2.6-2.59a2.597 2.597 0 00-2.6-2.59h-1.3zm2.6 2.59c0 .714-.583 1.295-1.3 1.295v-2.59a1.3 1.3 0 011.3 1.295zm5.85-2.59v1.295h-2.6v.648h1.95V8.57h-1.95v1.943h-1.3V6.628a1.3 1.3 0 011.3-1.295h2.6z"></path></svg>
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" style="background-color:white;margin-right: 10px; flex-shrink:0" fill="#CCCCCC" class="br2" v-else-if="scope.row.resourceType==CLMResourceType.PRO"><path d="M14.222 0H1.778C.8 0 0 .8 0 1.778v12.444C0 15.2.8 16 1.778 16h12.444C15.2 16 16 15.2 16 14.222V1.778C16 .8 15.2 0 14.222 0zM9.778 12.444H3.556v-1.777h6.222v1.777zm2.666-3.555H3.556V7.11h8.888v1.78zm0-3.556H3.556V3.556h8.888v1.777z"></path></svg>
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" style="background-color:white;margin-right: 10px; flex-shrink:0" fill="#7599FF" class="br2" v-else-if="scope.row.resourceType==CLMResourceType.PUBLISHED_TEMPLATE"><path d="M14.222 0H1.778C.8 0 0 .8 0 1.778v12.444C0 15.2.8 16 1.778 16h12.444C15.2 16 16 15.2 16 14.222V1.778C16 .8 15.2 0 14.222 0zM9.778 12.444H3.556v-1.777h6.222v1.777zm2.666-3.555H3.556V7.11h8.888v1.78zm0-3.556H3.556V3.556h8.888v1.777z"></path></svg>
                    <span v-html="getRowTitle(scope.row)"></span>
                    <el-tag v-if="[CLMResourceType.PUBLISHED_TEMPLATE,CLMResourceType.PRO].includes(scope.row.resourceType)" style="margin-left:10px" size="small" type="info" class="info-tag">模板</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="approvalStatus"
                  label="状态"
                  align="left"
                  v-if="tableView.showStatus.value"
                  style="height: 50px;padding: 0px;line-height: 50px;"
                  sortable="custom"
                  sort-by="approval_status"
                  width="120">
                <template #default="scope">
                  <div style="display: flex;justify-content: space-between;align-items: center" v-if="!trashed">
                    <div style="display:inline-block;"
                         v-if=" scope.row.deleteStatus == 1 ">
                      <span class="stage-circle stage-white"></span><span>已删除</span>
                    </div>
                    <div style="display:inline-block;"
                         v-else-if="scope.row.resourceType==CLMResourceType.PUBLISHED_TEMPLATE && scope.row.approvalStatus == 0 ">
                      <span class="stage-circle stage-yellow"></span><span>审批中</span>
                    </div>
                    <div
                        v-else-if="scope.row.resourceType==CLMResourceType.PUBLISHED_TEMPLATE && scope.row.approvalStatus == 1 ">
                      <span class="stage-circle stage-blue"></span><span>模板</span>
                    </div>
                    <div
                        v-else-if="scope.row.resourceType==CLMResourceType.PUBLISHED_TEMPLATE && scope.row.approvalStatus == 2 ">
                      <span class="stage-circle stage-red"></span><span>已驳回</span>
                    </div>

                    <div v-else-if="scope.row.approvalStatus==ContractApprovalStatus.DRAFT && scope.row.resourceType===CLMResourceType.CONTRACT">
                      <span class="stage-circle stage-gray"></span><span>草稿</span>
                    </div>

                    <el-tooltip content="定稿审批中" placement="top" style="display:inline-block;"
                                v-else-if=" scope.row.approvalStatus==ContractApprovalStatus.LAUNCH_READY_TO_SIGN">
                      <div>
                        <span class="stage-circle stage-yellow"></span><span>审批中</span>
                      </div>

                    </el-tooltip>
                    <div v-else-if="scope.row.approvalStatus==ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED">
                      <span class="stage-circle stage-purple"></span><span>定稿</span>
                    </div>
                    <div v-else-if="scope.row.approvalStatus==ContractApprovalStatus.LAUNCH_READY_TO_SIGN_REFUSED">
                      <span class="stage-circle stage-red"></span><span>已驳回</span>
                    </div>

                    <div v-else-if="scope.row.approvalStatus==ContractApprovalStatus.LAUNCH_SIGNED_PASSED">
                      <span class="stage-circle stage-green"></span><span>已签署</span>
                    </div>

                    <div v-else-if="scope.row.approvalStatus==ContractApprovalStatus.CANCELED">
                      <span class="stage-circle stage-black"></span><span>已取消</span>
                    </div>
                    <div v-else-if="scope.row.resourceType==CLMResourceType.PRO">
                      <span class="stage-circle stage-gray"></span><span>模板草稿</span>
                    </div>

                    <el-tooltip content="待处理" placement="top"
                                v-if="scope.row.deleteStatus===0 && ((scope.row.resourceType==CLMResourceType.PUBLISHED_TEMPLATE && scope.row.approvalStatus === 0 && userInfo && userInfo.isManagerRole) ||(scope.row.isApprover==true))">
                      <div class="warning"></div>
                    </el-tooltip>
                  </div>
                  <div style="display: flex;justify-content: space-between;align-items: center" v-else>
                    <div>
                      <span class="stage-circle stage-gray"></span><span>已删除</span>
                    </div>
<!--                    <div style="display:inline-block;"-->
<!--                         v-if="scope.row.resourceType==CLMResourceType.PUBLISHED_TEMPLATE && scope.row.approval_status == 0 ">-->
<!--                      <span class="stage-circle stage-blue"></span><span>模版</span>-->
<!--                    </div>-->
<!--                    <div v-else-if="scope.row.resourceType==CLMResourceType.CONTRACT">-->
<!--                      <span class="stage-circle stage-gray"></span><span>合同</span>-->
<!--                    </div>-->
<!--                    <div v-else-if="scope.row.resourceType==CLMResourceType.PRO">-->
<!--                      <span class="stage-circle stage-blue"></span><span>在线编辑文件</span>-->
<!--                    </div>-->
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="creatorName"
                  label="创建者"
                  align="left"
                  v-if="tableView.showName.value"
                  show-overflow-tooltip
                  sortable="custom"
                  sort-by="creator_name"
                  width="120">

              </el-table-column>
              <el-table-column
                  prop="otherParties"
                  label="当事人"
                  v-if="tableView.showOtherParties.value"
                  min-width="100"
                  sortable="custom"
                  sort-by="other_parties"
              >
                <template #default="scope">
                <span v-if="!scope.row.otherParties || getOtherParties(scope.row.otherParties).length == 0">

                </span>
                    <div  class="otherParties"
                          v-else>
                        <el-tag size="small" type="info" class="info-tag"
                                @mouseenter="handleOtherPartiesPointerEnter($event,scope.row.otherParties)"
                                @mouseleave="handleOtherPartiesPointerLeave"
                        >
                          {{
                           composeOtherParties(scope.row.otherParties)
                          }}
                        </el-tag>
                    </div>

                </template>
              </el-table-column>
              <el-table-column
                  prop="updateTime"
                  label="最近更新"
                  v-if="tableView.showTime.value"
                  width="140"
                  sortable="custom"
                  sort-by="update_time"
              >
                <template #default="scope">
                  {{
                    transformTimeInfoForList(scope.row.updateTime,Date.now())
                  }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="tagNameStr"
                  label="标签"
                  min-width="160"
                  v-if="tableView.showTag.value"
                  align="left">
                <template #default="scope">
                  <div style="display: flex; flex-direction: row;line-height: 46px;align-items: center">
<!--                    <div v-if="scope.row.resourceType==CLMResourceType.CONTRACT" style="height: 46px;line-height: 46px">-->
<!--                      <span @click.stop="getContractInfo(scope.row.id,scope.$index)" class="icon-more"></span>-->
<!--                    </div>-->
                    <div style=" padding: 10px 0 10px 0;height: 46px;display: flex;align-items: center">
<!--                      <el-tag size="small" class="contract-tag"-->
<!--                              :style="{order: getTagOrder(item)}"-->
<!--                              :class="{'system-tag':item.teamId == 1}"-->
<!--                              v-for="(item,index) in removeDuplicateTags(scope.row.tags)" :key="index"-->
<!--                              v-show="item!=null" style="margin-right: 10px">-->
<!--                        {{ item.name }}-->
<!--                      </el-tag>-->
                      <CertificateTag
                          v-for="(item,index) in removeDuplicateTags(scope.row.tags)" :key="index"
                          :style="{order: getTagOrder(item)}"
                          :tag-data="item"
                          :just-for-show="true"
                          v-show="item!=null"
                          style="margin-right: 10px"
                      />
                    </div>
                  </div>
                </template>
              </el-table-column>
            </template>

          </MetaListTable>

        </div>
      </div>

      <el-dialog v-model="dialogInfoVisible" custom-class="contract-detail" title="详情信息" width="400px">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="标签" name="first">
            <div class="tag-display-container" style="text-align: left">
              <el-tag closable @close="removeTag(item.id,index)" v-for="(item,index) in contractDetail.tags"
                      :key="index"
                      v-show="item!=null" style="margin-right: 10px">{{ item.name }}
              </el-tag>
            </div>
            <div style="text-align: left;margin-top: 20px">
              <el-input v-model="tagName" label="请输入标签名" style="width: 50%;margin-right: 20px"></el-input>
              <el-button @click="addByInput(tagName)" type="primary" :disabled="tagName==''">添加</el-button>
              <h2>常用</h2>
              <div style="text-align: left">
                <a class="contract-tag " @click="addTag(item)" v-for="(item,index) in taglist" :key="index"
                   v-show="item!=null">
                  <span class="tag-content">{{item}}</span>
                </a>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="版本" name="second">
            <el-table :data="contractDetail.versions">
              <el-table-column
                  prop="fileName"
                  label="文件名"
                  min-width="50%">
              </el-table-column>
              <el-table-column
                  prop="updateTime"
                  label="更新时间"
                  min-width="50%">
                <template #default="scope">
                  {{ new Date(scope.row.updateTime).toLocaleString() }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>

      <el-dialog
          title="邀请成员"
          v-model="organizationInviteOuterUserDialogVisible"
          @opened="organizationInviteOuterUserDialogOpened"
          center>
        <organizationInviteUser
            ref="organization-invite-user"
            :organization_name="rootOrganization.name"
            :organization_id="rootOrganization.id">
        </organizationInviteUser>
        <template #footer>
              <span class="dialog-footer">
                <el-button @click="organizationInviteOuterUserDialogVisible = false">关 闭</el-button>
              </span>
        </template>
      </el-dialog>
    </div>
  </div>
  <ContractCalendars></ContractCalendars>
  <cancel-contract-dialog :contractIds="contractIds" :teamId="userInfo.mainSiteTeamId" ref="cancelContractDialog" @onContractCanceled="loadResource"></cancel-contract-dialog>
  <div class="otherParties-toolTip" ref="sToolTip" v-show="sToolTipVisible">
    {{sToolTipContent}}
  </div>
</template>
<script>
// 合同的过滤器类型
import {More, Filter} from "@element-plus/icons"
import config from "../../config";
import {
  addContractTag,
  getContractDashboardData,
  getContractViewDetail,
  getResourceList,
  removeContractTag,
  getTeamOrganizations,
  cancelContract,
  deleteResources, restoreResource, getTags
} from "../../api/api";
import ContractSideMenu from "../../components/ContractSideMenu";
import organizationInviteUser from "../../components/OrganizationInviteUser";
import {ElLoading, ElMessage, ElNotification} from "element-plus";
import {MetaMessage, colorLog, formatDate, debounce, transformTimeInfoForList} from "../../util";
import {CLMResourceType, ContractApprovalStatus, FilterType, getOtherParties, composeOtherParties} from "../../constant/contract";
import ContractCalendars from "../../components/ContractCalendars";
import MetaListTable from "../../components/list/MetaListTable";
import MetaListPagination from "../../components/list/MetaListPagination";
import MetaListSelector from "../../components/list/MetaListSelector";
import MetaRightSideHeader from "../../components/universalUI/MetaRightSideHeader";
import {globalTagClick} from "../../assets/js/tag/tag";
import MetaListColumn from "../../components/list/MetaListColumn";
import CancelContractDialog from "../../components/contract/CancelContractDialog";
import CertificateTag from "../../components/tag/CertificateTag";
import {TagStatus, TagType} from "../../components/tag/configure";
const ContractFilterType = {
  ALL: 0,
  WAIT_APPROVE_BY_ME: 1,
  MY_CONTRACT_WAIT_APPROVE: 2,
  WAIT_SETTING_META_APPROVAL_PROCESS: 3,
  SIGNED: 4,
};

export const FileContentType = {
  PRO:0,
  PDF:1,
  DOCX:2,
  DOC:3
}


export default {
  name: "ContractHome",
  components: {
    CancelContractDialog,
    MetaListColumn,
    MetaRightSideHeader,
    MetaListSelector,
    MetaListPagination,
    MetaListTable,
    ContractSideMenu,
    More,
    organizationInviteUser,
    Filter,
    ContractCalendars,
    CertificateTag
  },
  data: function () {
    return {
      colorLog,
      FilterType,
      CLMResourceType,
      ContractApprovalStatus,
      metaMessage: new MetaMessage(),
      inviteOrganizationId: this.$route.query.vo,
      ContractFilterType: ContractFilterType,
      dashboardData: {
        contractCountWaitApproveByMe: 0,// 待我审批的合同数量
        contractCountAfterLaunchApprovalProcess: 0,// 我发起的待审批的合同
        publishedTemplateCountWaitApprove: 0, // 待我审批的合同模板数量
        contractCountWaitSetApprovalProcess: 0,// 待我审批的上传合同数量
        signedContractCount: 0,// 已签署合同数量
        publishedTemplateApprover: false,// 是否能够审批模板的发布，修改合同的权限信息（谁可以审批，谁能够看见合同.....）
      },
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      currentIndex: this.$route.query.pageIndex?parseInt(this.$route.query.pageIndex):1,
      size: 20,
      totalCount: 0,
      totalPage: 0,
      currentCount:0,
      contractList: [],
      templateHost: config.baseUrl,
      dialogInfoVisible: false,
      contractDetail: {},
      activeName: "first",
      sToolTipContent:'',
      sToolTipVisible:false,
      currentContractIndex: -1,
      tagName: "",
      taglist: [],
      filter: 0,
      rootOrganization: null,
      organizationInviteOuterUserDialogVisible: false,
      searchKey: '',
      searchResult: [],
      loadingInstance: null,
      tableHeight: document.body.clientHeight - 148,
      checkFilterMenuVisible: false,
      actionMenuVisible: false,
      searchMenuVisibie: false,
      canCloseSearchDiv: true,
      showGetStarted: false,
      showGetStartedBtn:true,
      tableView: {
        showStatus: {label: "状态", value: true},
        showName: {label: "创建者", value: true},
        showOtherParties:{label: "相对方",value: true},
        showTime: {label: "上次更新", value: true},
        showTag: {label: "标签", value: true},
      },
      filterList: [],
      filterMenu:[
        {
          type:"类型",
          key:"type",
          items:[
            {name:"合同",value: 4},
            {name:"模板",value: 3}
          ]
        },
        {
          type:"状态",
          key:"status",
          items:[
            {name:"模版",class:"stage-circle stage-blue",value:0,checked:false},
            {name:"草稿",class:"stage-circle stage-gray",value:2,checked:false},
            {name:"审批中",class:"stage-circle stage-yellow",value:3,checked:false},
            {name:"定稿",class:"stage-circle stage-purple",value:4,checked:false},
            {name:"已驳回",class:"stage-circle stage-red",value:5,checked:false},
            {name:"已签署",class:"stage-circle stage-green",value:6,checked:false},
            {name:"已取消",class:"stage-circle stage-black",value:7,checked:false},
            {name:"已删除",class: "stage-circle stage-white",value: 8,checked:false}
          ]
        },
        {
          type: "标签",
          key: "tags",
          items: [
            {name: "买卖", class: "stage-circle stage-blue", value: 0},
            {name: "租赁", class: "stage-circle stage-blue", value: 0},
            {name: "测试", class: "stage-circle stage-blue", value: 0},
            {name: "律师函", class: "stage-circle stage-blue", value: 0},

          ]
        },
        {
          type: "待办",
          key:'tags',
          items:[]
        }
      ],
      lastStatusCheckIndex:0,
      needAddSearchTag:true,
      searchName:'',
      tags:[],
      checkedList:[],
      deleteMenuVisible:false,
      trashed:false,
      sort:'desc',
      sortBy:'update_time,title',
      paginationInit:this.$route.query.pageIndex?this.$route.query.pageIndex==='1'?true:false:true,
      FileContentType,
      debounceTagClickFun:debounce((type,text)=>{
        globalTagClick(`contract-files-search-${type}`,{text});
      },500)
    };
  },
  computed:{
    showCancel(){
      return this.checkedList.every(item => item.resourceType == CLMResourceType.CONTRACT) && this.checkedList.length>0 && this.checkedList.find((item)=>item.status===ContractApprovalStatus.CANCELED)==null &&!this.$route.query.trashed
    },
    showDelete(){
      return this.checkedList.every(item=>item.deleteStatus===0)
    },
    showCancelDelete(){
      return this.checkedList.every(item=>item.deleteStatus===1)
    },
    showCompletelyDelete(){
      return this.trashed &&this.userInfo &&this.userInfo.isManagerRole
    },
    contractIds(){
      return this.checkedList.filter(item=>item.resourceType===CLMResourceType.CONTRACT).map(item=>item.id)
    }
  },
  mounted() {
    // let that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight
        this.tableHeight = window.screenHeight - 148
      })()
    }
    if(localStorage.getItem("dismissGetStart")=="true"){
      this.showGetStartedBtn=false
    }
    if (this.userInfo && this.userInfo.recentlyUsedContractTags) {
      this.taglist = this.userInfo.recentlyUsedContractTags.split(',')
    }
    getTags().then(res => {
      console.log('getTags  res:', res);
      this.tags=res.data.data;
      this.loadResource()
    }).catch(err => {
      console.error("initContractTags   err:", err);
    })



    //接收路由参数,来显示第几页

    //打开左侧状态菜单
    // this.$nextTick(() => {
    //   console.log('contractFile refs:', this.$refs);
    //   this.$refs.sideMenu.$refs.elMenu.open('1');
    // });
  },
  watch:{
    tableView:{
      handler(val){
        for(let key in val){
          if(!val[key].value){
            globalTagClick(`contract-files-column-sift-state-${key}`);
            break;
          }
        }
      },
      deep:true
    },
    '$route.query': function(){

      this.loadResource();
    },
    'contractList':{
      handler:function (){
        this.checkedList=this.contractList.filter((item)=>item.checked);
      },
      deep:true
    },
    searchKey(val){
      this.debounceTagClickFun('column-sift',val);
    }



  },
  methods: {
    getOtherParties,
    composeOtherParties,
    formatDate,
    transformTimeInfoForList,
    getTagOrder({name,teamId}){
      let filter = this.filterList.find(ftr => ftr.type === '标签');
      if(!filter) {
        //随便给个css不支持的属性让它失效
        return 'invalid';
      }
      let names = filter.names;
      let index = names.indexOf(name);
      if(index > -1){
        return index - names.length;
      }
      return 'invalid';
    },
    handleOtherPartiesPointerEnter(e,parties){
      parties = getOtherParties(parties);
      this.sToolTipVisible = true;
      this.sToolTipContent = parties.join('、');

      this.$nextTick(()=>{
        const node = this.$refs.sToolTip;
        let {x:nodeX,y:nodeY,width:nodeW,height:nodeH} = node.getBoundingClientRect();
        let {x,y,height,width} = e.target.getBoundingClientRect();
        let top = y - nodeH - 10;
        let left = x - (nodeW - width) / 2;
        node.style.setProperty('top',top + 'px');
        node.style.setProperty('left',left + 'px');
      })
    },
    handleOtherPartiesPointerLeave(){
      this.sToolTipVisible = false;
    },
    loadResource: function () {
      if(this.$route.query.pageIndex){
        this.currentIndex=parseInt(this.$route.query.pageIndex)

      }
      else{
        this.currentIndex=1;
      }
      if(this.needAddSearchTag){
        this.filterList=[];
        if(this.$route.query.type){
          let type=JSON.parse('[' + this.$route.query.type + ']');
          for(let i = 0;i<2;i++) {
            if (type.indexOf(this.filterMenu[0].items[i].value)>-1) {
              this.addFilter("类型", "type", this.filterMenu[0].items[i].name, this.filterMenu[0].items[i].value, false, false)
              this.filterMenu[0].items[i].checked=true;
            }else{
              this.filterMenu[0].items[i].checked=false;
            }
          }
        }

        if(this.$route.query.status  ) {
          let status = JSON.parse('[' + this.$route.query.status + ']');
          for(let i = 0;i<8;i++){
            if(status.indexOf(this.filterMenu[1].items[i].value)>-1){
              this.addFilter("状态","status",this.filterMenu[1].items[i].name,this.filterMenu[1].items[i].value,false)
              this.filterMenu[1].items[i].checked=true;
            }
            else{
              this.filterMenu[1].items[i].checked=false;
            }
          }
        }
        if(this.$route.query.tags){
          let tags = JSON.parse('[' + this.$route.query.tags + ']');
          for(let i in tags){
            let tag =tags[i];
            this.addFilter("标签", "tags", tag, tag, false)
          }
        }
        if(this.$route.query.keywords){
            this.searchName=this.$route.query.keywords;

            this.addFilter("包含","keywords",this.searchName,this.searchName,false,true)
        }
      }

      if(this.$route.query.trashed=='true'){
        this.trashed=true;
      }
      else{
        this.trashed=false;
      }

      if(!this.$route.query.type){
        for(let i = 0;i<2;i++){
          this.filterMenu[0].items[i].checked=false;
        }
      }

      if(!this.$route.query.status){
        for(let i = 0;i<8;i++){
          this.filterMenu[1].items[i].checked=false;
        }
      }
      if(!this.$route.query.keywords){
        this.searchName="";
      }





      let status = this.$route.query.status;
      let tags = this.$route.query.tags;
      let resourceType = 0;
      if(this.$route.query.type){
        JSON.parse('['+this.$route.query.type+']').forEach(item=>{
          resourceType+=item
        });
      }

      let params = {
        pageIndex: this.currentIndex - 1,
        pageSize: this.size,
        filterType: this.filter,
        statuses: status,
        tags: tags,
        resourceType:resourceType,
        keywords: this.searchName,
        trashed: this.trashed,
        sort:this.sort,
        orderBy:this.sortBy

      }
      getResourceList(params).then(res => {
        if (res.status == 200) {

          this.contractList = res.data.data.content;
          const tagsSift = (tag,checked) => {
            //区分待办和普通标签
            const isNeedResolve = tag.type === TagType.NEED_RESOLVE;

            //index 为 3是待办
            this.filterMenu[isNeedResolve ? 3 : 2].items.push({
              name:tag.name,
              value:tag.name,
              checked
            })
          };

          this.cancelAll();
          this.totalCount = res.data.data.totalElements;
          this.totalPage = res.data.data.totalPages;
          this.currentCount = res.data.data.numberOfElements;
          this.filterMenu[2].items=[];
          this.filterMenu[3].items=[];
          if(this.$route.query.tags) {
            let tags = JSON.parse('[' + this.$route.query.tags + ']');
            for(let tag of this.tags){
              if (tags.includes(tag.name)) {
                tagsSift(tag,true);
              }
              else{
                tagsSift(tag,false);
              }
            }
          } else{
            this.tags.forEach(t => tagsSift(t, false));
          }
          this.needAddSearchTag = true;
        }
      })



    },
    loadData: function () {
      getContractDashboardData().then(res => {
        if (res.data.code === 0) {
          this.dashboardData = res.data.data;
        } else {
          this.metaMessage.error(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        this.metaMessage.error('获取统计数据失败');
      });
      this.loadResource();
    },
    jumpTo: function (name) {
      this.$router.push({name: name})
    },
    jumpToContractListView: function (filterType) {
      this.$router.push({
        name: 'contractList',
        query: {
          filterType: filterType
        }
      })
    },
    changeTemplatePublishSetting: function (row) {
      this.$router.push({
        name: 'templatePublish',
        query: {
          templateId: row.id
        }
      })
    },
    deleteContract: function () {
      ElMessage.error("暂时不能删除")
    },
    currentChange: function (newValue) {
      if(!this.paginationInit){
        this.paginationInit=true;
      }else {
        this.currentIndex = newValue
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.pageIndex = this.currentIndex;
        this.$router.push({
          name: "files",
          query: query
        })
      }
    },
    sizeChange(){

    },

    watchProFile: function (fileId, fileName) {
      this.$router.push({
        name:"doc",
        query:{
          fileId:fileId
        }
      })
    },
    setContractApprovalProcessAndPermission: function (row) {
      this.$router.push({
        name: 'contractProcessPermission',
        query: {
          contractId: row.id
        }
      })
    },
    getContractInfo: function (contractId, index) {

      getContractViewDetail(contractId).then(res => {
        if (res.status == 200) {
          this.contractDetail = res.data.data;
          this.currentContractIndex = index;
          this.dialogInfoVisible = true;
        }


      })
    },
    removeTag(tagId, index) {

      removeContractTag(this.contractDetail.id, tagId).then(res => {
        if (res.status == 200) {
          this.contractDetail.tags.splice(index, 1);
          this.contractList[this.currentContractIndex].tagName.splice(index, 1)
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error("网络错误")
      })

    },
    addTag(tagName) {
      for (let index in this.contractDetail.tags) {
        if (this.contractDetail.tags[index].name == tagName) {
          ElMessage.error("标签已存在!")
          return false;
        }
      }
      addContractTag(this.contractDetail.id, tagName).then(res => {
        if (res.status == 200) {

          this.contractDetail.tags.push(res.data.data);
          if (!this.contractList[this.currentContractIndex].tagName) {
            this.contractList[this.currentContractIndex].tagName = []
          }
          this.contractList[this.currentContractIndex].tagName.push(tagName)
          this.filterMenu[2].items.push({
            name: tagName,
            class: "stage-circle stage-blue",
            value: tagName,
            checked: false
          });
        }
      }).catch(e => {
        console.error(e)
        ElMessage.error("网络错误");
      });


    },
    addByInput(tagName) {
      if (this.addTag(tagName) != false) {
        if (this.taglist.length == 5) {
          this.taglist.splice(0, 1);

        }
        this.taglist.push(tagName);
        this.tagName = '';
      }
    },
    inviteUser: function () {
      console.log('inviteUser  this.userInfo === ', this.userInfo);
      getTeamOrganizations(this.userInfo.mainSiteTeamId).then(res => {
        console.log('getTeamOrganizations re === ', res);
        if (res.data.code === 0) {
          this.rootOrganization = null;
          for (const o of res.data.data) {
            if (!o.parentId) {
              this.rootOrganization = o;
              break;
            }
          }
          if (this.rootOrganization != null) {
            this.organizationInviteOuterUserDialogVisible = true;
          } else {
            ElMessage.error("未找到根节点组织结构");
          }
        } else {
          ElMessage.error("获取根节点组织结构失败");
        }
      }).catch(err => {
        console.error('inviteUser  getTeamOrganizations  err:', err);
        ElMessage.error("获取根节点组织结构失败");
      })
    },
    organizationInviteOuterUserDialogOpened: function () {
      this.$refs["organization-invite-user"].initQRCode();
    },
    clearSearchKeyWords: function () {
      const searchInput = document.querySelector('.contracts-files-input');
      searchInput.value = '';
    },
    formatDateFromTimestamp: function (timestamp, formatTemplate) {
      const date = new Date(timestamp);
      const yy = date.getFullYear();
      const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      const dd = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      const HH = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
      const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return formatTemplate.replace("yyyy", yy).replace("MM", MM).replace("dd", dd)
          .replace("HH", HH).replace("mm", mm).replace("ss", ss);
    },
    viewContract: function (contractId) {
      let versionContractPage = this.$router.resolve({
        name: 'contractView',
        query: {
          id: contractId
        }
      })

      window.open(versionContractPage.href)
    },
    openLoading: function () {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        body: true,
        lock: true,
        spinner: 'el-icon-loading'
      });
    },
    closeLoading: function () {
      this.loadingInstance.close();
      this.loadingInstance = null;
    },
    log(x) {
      console.log(x)
    },

    addFilter(type, key,item,value,loadData,single) {
      if(key == 'keywords'){
        this.debounceTagClickFun('file',value);
      }else{
        globalTagClick('contract-files-sift-state-'+type, {value});
      }
      var target = this.filterList.find((item) => {
        return item.type == type
      })

      if (target !== undefined) {
        if(single){
          target.names=item;
          target.values=value;
        }
        else {

          let index = target.names.indexOf(item)

          if (index>-1) {
            target.names.splice(index, 1)
            target.values.splice(index, 1)

          } else {
            target.names.push(item)
            target.values.push(value)

          }
        }
      } else {
        if(single){
          this.filterList.push({type: type,key:key,names: item,values:value})
        }
        else{
          let values = [];
          values.push(value)
          this.filterList.push({type: type,key:key,names: [item],values:values})
        }
      }


      if(loadData) {
        let query = {trashed:this.trashed,pageIndex:1};
        for (let index in this.filterList) {
          if(this.filterList[index].values.length>0) {
            if(this.filterList[index].type!='包含') {
              let str = JSON.stringify(this.filterList[index].values);
              query[this.filterList[index].key] = str.substr(1, str.length - 2);
            }
            else{
              query[this.filterList[index].key] = this.filterList[index].values;
            }
          }
          else{
            this.filterList.splice(index,1);
            index--;
          }
        }
        this.needAddSearchTag=false;
        this.$router.push({
          name: 'files',
          query: query
        })
      }
    },
    checkAll(){
      this.contractList.forEach((item)=>{
        item.checked=true;
      })
    },
    cancelAll(){
      this.contractList.forEach((item)=>{
        item.checked=false;
      })
    },
    checkByStatus(resourceType1,status,resourceType,status2){
      this.contractList.forEach((item)=>{
        if(item.deleteStatus===0) {
          if ((item.resourceType===resourceType1 &&item.approvalStatus === status) || (item.resourceType === resourceType && item.approvalStatus!==null && item.approvalStatus === status2)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        }else{
          item.checked = false;
        }
      })
    },
    checkDelete(){
      this.contractList.forEach((item)=>{
        if(item.deleteStatus===1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      })
    },
    checkTemplate(){
      this.contractList.forEach((item)=>{
        if(item.resourceType==CLMResourceType.PUBLISHED_TEMPLATE&&item.deleteStatus==0){
          item.checked=true;
        }
        else{
          item.checked=false;
        }
      })
    },
    checkPro(){
      this.contractList.forEach((item)=>{
        if(item.resourceType==CLMResourceType.PRO&&item.deleteStatus==0){
          item.checked=true;
        }
        else{
          item.checked=false;
        }
      })
    },
    disMissGetStart(){

      localStorage.setItem("dismissGetStart","true");
      this.showGetStartedBtn=false;
    },
    downloadFiles(){
      let ids=[];
      this.checkedList.forEach((item)=>{
        if(item.resourceType==CLMResourceType.CONTRACT){
          ids.push(item.fileId)
        }else if(item.resourceType==CLMResourceType.PUBLISHED_TEMPLATE){
          ids.push(item.file_id)
        }else if(item.resourceType==CLMResourceType.PRO){
          ids.push(item.fileId)
        }

      })

      let link = document.createElement('a')
      link.href = config.baseUrl+'packageDownload/'+ids;
      link.click()

    },
    clearFilter(index){
      if(this.filterList[index].type=="包含"){
        this.searchName=""
      }
      this.filterList.splice(index,1);
      let query={trashed:this.trashed,pageIndex:1}
      for(let i in this.filterList){
        if(this.filterList[i].type!='包含') {
          let str = JSON.stringify(this.filterList[i].values);
          query[this.filterList[i].key] = str.substr(1, str.length - 2)
        }
        else{
          query[this.filterList[i].key] = this.filterList[i].values
        }
      }

      this.$router.push({name:'files',query:query})
    },
    handleClick(row,column,cell,event){
      if(this.trashed)return;
      // colorLog.red(row,column,cell,event)
      globalTagClick('contract-files-table-click-'+row.resourceType);
      if(row.resourceType==CLMResourceType.PUBLISHED_TEMPLATE){
        this.changeTemplatePublishSetting(row)
      }else if(row.resourceType==CLMResourceType.CONTRACT){
        this.$router.push({name:'contractView',query:{id:row.id}})
      }else if(row.resourceType==CLMResourceType.PRO){
        this. watchProFile(row.fileId,row.name)
      }



    },
    sortChange(e) {

      if (e.order !== null) {

        this.sortBy = e.column.sortBy;
        if (e.order === 'descending') {
          this.sort = 'desc';
        } else {
          this.sort = 'asc';
        }
      }else{
        this.sort = 'desc';
        this.sortBy = 'update_time'
      }
      this.loadResource();
    },
    cancelContract(){
      this.$refs.cancelContractDialog.show()
    },
    deleteResources(completely){
      let resources=this.checkedList;


      deleteResources(resources,completely).then(res=>{
        if(res.status==200){
          let success = 0;
          let failed = 0;
          success+=res.data.data.success.length;
          failed+=res.data.data.failed.length;
          ElNotification({
            title: '执行结果',
            message: `成功:${success}个,失败:${failed}`
          })

          this.loadResource();
        }
      })
    },
    cancelDeleteResources(){
      let resources=this.checkedList;

      restoreResource(resources).then(res=>{
        if(res.data.code==0){
          this.metaMessage.success("恢复文件成功");
          this.loadResource();
        }
      }).catch(err=>{
        console.error("restoreResource err===>"+err);
        this.metaMessage.error("恢复文件失败")
      })
    },
    onSearchInputEnter: function () {
      const searchInput = document.querySelector('#searchInput');
      const searchKey = searchInput.value;
      this.$router.push({
        name: 'advancedSearch',
        query: {
          query: searchKey
        }
      })
    },
    removeDuplicateTags(tags){
      if(!tags)return null;
      let map = {};
      let result = [];
      tags.forEach((item)=>{
        if(map[item.name]==null){
          map[item.name]=1;
          result.push(item);
        }
      })
      return result;
    },
    getRowTitle: function (row) {
      let title = row.title;
      if (row.resourceType === CLMResourceType.PRO) {
        title = title.substring(0, title.lastIndexOf("."));
      }
      return title;
    }

  },


}
</script>
<style scoped>
.custom-popper{
  transition: none !important;
}
.right-panel {
  padding: 0;
  min-width: 570px;

  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  width: calc(100% - 283px);

}


.upper-section .title-container {


}

.upper-section .title {
  height: 50px;
  margin-top: 20px;
  text-align: left;
  font-size: 38px;
  background: linear-gradient(90deg, #67bdff 0, #297fff 100%);
  color: transparent;
  -webkit-background-clip: text;
  font-weight: bold;
  user-select: none;
  display: inline-block;
}

.upper-section .sub-title {
  margin: auto;
  height: 24px;
  text-align: left;
  font-size: 22px;
  color: #777;

  user-select: none;
  display: block;
}

.entry-container {
  overflow: hidden;
  display: inline-block;
  padding-left: 50px;
}

.entry-points {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  width: 500px;
  padding-top: 32px;
}

.entry-point {
  background-color: #767fbc;
  border-radius: 12px;
  padding: 14px 25px;
  cursor: pointer;
  width: 276px;
  box-sizing: border-box;
  margin-left: 10px;
}

.entry-point:nth-child(1) {
  margin-left: 0;
}

.entry-point:hover {
  background: linear-gradient(#5176f7, #4ca0fb);
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.entry-point .name {
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}


.entry-point .entry-icon {
  width: 31px;
  height: 31px;
  background: url(../../assets/images/icon_xy.png) center;
  background-size: auto;
  transform: scale(.7);
}

.entry-point .des {
  font-size: 15px;
  padding-top: 5px;
  color: #dfebff;
}

.upper-setting {
  display: inline-block;
  overflow: hidden;
  float: right;
}

.upper-setting .setting {
  margin-top: 32px;
  margin-right: 32px;
  background: url(../../assets/images/setting.png) no-repeat;
  background-size: 30px;

  width: 30px;
  height: 30px;
  transition: all 1s;
  cursor: pointer;
}

.upper-setting .setting:hover {
  transform: rotateZ(360deg);
}

.below-section {

}

.below-section .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 910px;
  margin: 28px auto 0;
}

.below-section .item {
  display: flex;
  background-color: white;
  cursor: pointer;
  border-radius: 14px;
  padding: 26px 26px;
  margin-bottom: 32px;
}

.item-icon {
  height: 31px;
  width: 28px;
}

.item-icon-0 {
  background: url(../../assets/images/icon_rlzy.png) top no-repeat;
  background-size: 100% 100%;
}

.item-icon-1 {
  background: url(../../assets/images/icon_gscl.png) top no-repeat;
  background-size: 100% 100%;
}

.item-icon-2 {
  background: url(../../assets/images/icon_trz.png) top no-repeat;
  background-size: 100% 100%;
}

.item-icon-3 {
  background: url(../../assets/images/mcn.png) top no-repeat;
  background-size: 100% 100%;
}

.item-right {
  padding: 2px 1px;
  margin-left: 13px;
  width: 347px;
  box-sizing: border-box;
}

.item-title {
  display: flex;
  justify-content: space-between;
}

.item .name {
  font-weight: 600;
  font-size: 20px;
}

.item .more {
  display: flex;
  font-size: 12px;
  color: #333333;
  cursor: pointer;
  align-items: center;
}

.item .more .more-icon {
  background: url(../../assets/images/icon_gd.png) top no-repeat;
  background-size: 100% 100%;
  width: 5px;
  height: 8px;
  margin-left: 1px;
}

.item .more:hover {
  text-decoration: underline;
}

.item .templates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 22px;
  min-height: 26px;
}

.templates .template {
  border-radius: 5px;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 80px;
  cursor: pointer;
  background-color: #f1f3f9;
}

.templates .template:hover {
  background-color: #efefef;
}

.template .template-name {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  color: #333333;
  font-size: 12px;
  text-align: center;
  text-overflow: ellipsis;
}

.contract-status {
  height: 30px;
  line-height: 20px;
  border-radius: 4px;
  padding: 5px;
  width: 100px;
  border: 0px;
  text-align: center;
  font-weight: bold;

}

.header-left .name {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  color: rgb(152, 152, 152);
}

.header-left .name:hover {
  color: black;
}










.header-left .active {
  color: black;
}

.warning {
  width: 20px;
  height: 20px;
  background: url(../../assets/images/exclamation.png) no-repeat;
  background-size: 20px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;

}

.icon-more {
  background: url(../../assets/images/more.png) no-repeat;
  background-size: 36px;


}

.icon-more:hover {
  background: url(../../assets/images/more_active.png) no-repeat;
  background-size: 36px;
}

/*搜索 ---------------------------------- */
.search-container {
  padding: 5px 20px 5px 0;
  text-align: left;
}

.search-container-active {
  border-color: #40a9ff
}

.contracts-files-input-wrapper {
  padding: 0 10px;
  height: 32px;
  border-radius: 2px;
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  text-align: start;
}

.contracts-files-input-prefix {
  left: 14px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  color: #b7bec7;
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.contracts-files-input {
  padding-left: 30px;
  padding-right: 10px;
  height: 32px;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  background-image: none;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  border-radius: 4px;
  transition: all .3s;
}

.contracts-files-input:focus {
  outline: 0;
  border: none;
  box-shadow: none;
}

.contracts-files-input:focus, .contracts-files-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.contracts-files-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  color: rgba(0, 0, 0, .65);
  line-height: 0;
  transform: translateY(-50%);
  font-size: 16px;
  color: #646a73;
  right: 10px;
  cursor: pointer;
}

.search-button {
  background-color: #0a84ff;
  color: white;
  padding: 5px;
  cursor: pointer;
}

.search-result-container {
  display: none;
  background-color: white;
  padding-bottom: 20px;
}

.search-result-item {
  padding: 5px 20px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: rgba(242, 246, 252, 1);
  min-height: 50px;
  vertical-align: middle;
  display: flex;
  text-align: center;
  align-items: center;
}

.search-result-item:hover {
  background: rgba(242, 246, 252, 1);
}

.search-result-close-button {
  margin-left: 10px;
  background-color: #0a84ff;
  color: white;
  padding: 5px;
  cursor: pointer;
}

.sri-header img {
  width: 28px;
  height: 28px;
}

.search-result-count {
  text-align: left;
  padding: 5px 20px;
}

.srii {
  display: inline-block;
}

.sri-header {
  display: flex;
  height: 30px;
  line-height: 30px;
}

.sri-info {
  width: 50%;
}

.sri-ct {
  margin-left: 30px;
  width: 200px;
}

.sri-status {
  margin-left: 30px;
}

.sri-name {
  margin-left: 5px;
  cursor: pointer;
}

.sri-contract-info {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  cursor: pointer;
}

.sri-content {
  margin-top: 10px;
  font-size: 14px;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-align: left;
}

.contract-list {
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}




.pd0 {
  padding: 0 10px;
}



.get-started {
  position: fixed;
  right: 89px;
  bottom: 25px;
  z-index: 999999;
}

.get-started-content {
  display: flex;
  flex-direction: column-reverse;

  justify-content: flex-start;
}


.item .el-badge__content.is-fixed {
  top: 5px;
  right: 15px;
  background: white;
  color: #0a84ff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);

}

.check-mark-content {
  padding: 0 0px 5px 0px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  position: relative;
  right: 10px;
  width: 350px;
}

.check-mark svg {
  margin-right: 8px;
  vertical-align: center;
  display: block;
}

.get-started h3 {
  margin: 10px 0;
}

.circle {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-right: 10px;
}

.circle:hover {
  border: 2px solid #0a84ff;
}

.mt10 {
  margin-top: 10px;
}

.ml2 {
  margin-left: 1rem;
}

.dismiss{
  color: gray;font-style: italic;
  cursor: pointer;
}

.dismiss:hover{
  color: #1E9FFF;
}

.any-checked{
  background:white;
  border-color:#1E9FFF;;
}

.contract-tag {
  cursor: pointer;
  margin-bottom: 0;
}


.bg-search{
}
.otherParties{
  padding: 10px 0 10px 0;height: 46px;display: flex;align-items: center;
}

.right-panel >>> .contract-detail .el-tabs__header{
  margin: 0 !important;
}

.tag-display-container .el-tag{
  margin: 10px 10px 0 0;
}

.menu-item {
  padding: 10px;
  text-align: left;
  cursor: pointer;
}
.menu-item:hover {
  background: #e2e2e2;
}
.otherParties-toolTip::before{
  content:'';
  position: absolute;
  border: 5px solid;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: rgba(0,0,0,.6);
  border-bottom-width: 0;
  bottom: -5px;
  left: calc(50% - 5px);
}

.otherParties-toolTip{
  position: fixed;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  color:white;
  max-width: 280px;
  text-align: left;
  font-size: .875rem;
  word-wrap: anywhere;
  border-radius: 4px;
}
.menu-filter-item .el-checkbox{
  display: flex;
  width: 100%;
  align-items:center;
}
.menu-filter-item >>> .el-checkbox__input{
}
.menu-filter-item >>> .el-checkbox__label{
  min-width: 0;
  vertical-align: bottom;
  /*width: 100%;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*搜索 ---------------------------------- */
</style>
